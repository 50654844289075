import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

export default class MessageHub {
  constructor(apiurl) {
    let dev_mode =
      typeof window.app != "undefined" && window.app.settings.developer_mode;
    let log_level = dev_mode ? LogLevel.Information : LogLevel.Warning;

    let connection = new HubConnectionBuilder()
      .withUrl(`${apiurl}/messages`, {
        accessTokenFactory: () => this.getToken()
      })
      //.withAutomaticReconnect()
      .configureLogging(log_level)
      .build();
    connection.onreconnecting = this.onreconnecting;
    this.connection = connection;
  }

  getToken() {
    return localStorage.usertoken;
  }

  startConnection() {
    this.connection
      .start()
      .then(this.on_done)
      .catch(this.on_start_fail);
  }

  stopConnection() {
    this.connection.stop();
  }

  // Subscribe to "type" events on project
  // type:
  //   "project": Receive create / update / delete events for project
  // Replies with a Subscribed message
  subscribe(type, projectId) {
    return this.connection
      .invoke("Subscribe", type, projectId)
      .catch(this.on_invoke_fail);
  }

  // Subscribe to "type" events
  // type:
  //   "node": Receive node data from zone controller
  // Replies with a Subscribed message
  requestLiveValues(type, projectId, serial) {
    return this.connection
      .invoke("RequestLiveValues", projectId, serial)
      .catch(this.on_live_values_failed);
  }

  // Unsubscribe command for Subscribe
  // Replies with a Unsubscribed message
  unsubscribe(type, projectId) {
    return this.connection
      .invoke("Unsubscribe", type, projectId)
      .catch(this.on_invoke_fail);
  }

  requestNodeSamples(
    projectId,
    controllerSerial,
    nodeSerial,
    duration,
    interval
  ) {
    this.connection
      .invoke(
        "requestNodeSamples",
        projectId,
        controllerSerial,
        nodeSerial,
        duration,
        interval
      )
      .catch(this.on_live_values_failed);
      //console.log("requestNodeSamples for:"+nodeSerial);
  }

  // Unsubscribe command for SubscribePulse
  // Replies with a Unsubscribed message
  UnsubscribeLiveValues(type, projectId, id) {
    return this.connection.invoke("UnsubscribeLiveValues", projectId, id);
  }

  // Sends sample request to node
  // and sends them backed for fixed amount of time
  RequestLiveValuesNode(projectId, nodeId) {
    return this.connection
      .invoke("RequestLiveValues", "node", projectId, nodeId)
      .catch(this.on_invoke_fail);
  }

  SetFixVoltOutputI(projectId, controllerSerial, nodeSerial, value) {
    return this.connection
      .invoke(
        "SetFixVoltOutputI",
        projectId,
        controllerSerial,
        nodeSerial,
        value
      )
      .catch(this.on_set_fv_setting_fail);
  }

  SetFixVoltOutputV(projectId, controllerSerial, nodeSerial, value) {
    return this.connection
      .invoke(
        "SetFixVoltOutputV",
        projectId,
        controllerSerial,
        nodeSerial,
        value
      )
      .catch(this.on_set_fv_setting_fail);
  }

  SetFixVoltOutputRecovery(projectId, controllerSerial, nodeSerial, isOn) {
    return this.connection
      .invoke(
        "SetFixVoltOutputRecovery",
        projectId,
        controllerSerial,
        nodeSerial,
        isOn
      )
      .catch(this.on_set_fv_setting_fail);
  }

  SetFixVoltOutputOnOff(projectId, controllerSerial, nodeSerial, isOn) {
    return this.connection
      .invoke(
        "SetFixVoltOutputOnOff",
        projectId,
        controllerSerial,
        nodeSerial,
        isOn
      )
      .catch(this.on_set_fv_setting_fail);
  }

  // Temporary command for testing and fun times
  // Message will be sent to *all* connected clients
  sendMessage(user, message) {
    return this.connection
      .invoke("SendMessage", user, message)
      .catch(this.on_invoke_fail);
  }

  registerCallbacks(implementation) {
    this.connection.on("Subscribed", (type, id) =>
      implementation.subscribed(type, id)
    );
    this.connection.on("Unsubscribed", (type, id) =>
      implementation.unsubscribed(type, id)
    );

    /*     this.connection.on(
      "UpdateJob",
      (
        unknownparam,
        projectId,
        type,
        jobId,
        status,
        oldData,
        newData,
        message
      ) =>
        implementation.update_job(
          projectId,
          type,
          jobId,
          status,
          oldData,
          newData,
          message
        )
    );
 */
    this.connection.on(
      "UpdateJob",
      (tag, jobId, projectId, task, taskName, status, data, message) =>
        implementation.update_job(projectId, task, jobId, status, data, message)
    );
    /* EVENTS */
    this.connection.on(
      "ControllerOnline",
      (project_id, cntrl_serial, node_serial, timestamp, isOnline) =>
        implementation.controller_online_change(
          project_id,
          cntrl_serial,
          timestamp,
          isOnline
        )
    );

    this.connection.on("UpdateProject", project =>
      implementation.update_project(project)
    );

    this.connection.on("UpdateController", (projectId, controller) =>
      implementation.update_cntrl(projectId, controller)
    ); 
    this.connection.on("UpdateHardwareController", (projectId, cntrl_device) =>
      implementation.update_cntrl_device(projectId, cntrl_device)
    );

    this.connection.on("UpdateZone", (projectId, zone) =>
      implementation.update_zone(projectId, zone)
    );
    this.connection.on("UpdateNode", (projectId, node) =>
      implementation.update_node(projectId, node)
    );
    this.connection.on(
      "UpdateFormula",
      (projectId, cntrlId, zoneId, nodeId, channelId, formula) =>
        implementation.update_formula(
          projectId,
          cntrlId,
          zoneId,
          nodeId,
          channelId,
          formula
        )
    );
    this.connection.on("UpdateNodeChannel", (projectId, zoneId, channel) =>
      implementation.update_nodechannel(projectId, zoneId, channel)
    );
    this.connection.on(
      "AlarmChannel",
      (projectId, controllerId, nodeId, channelId) =>
        implementation.alarm_channel(projectId, controllerId, nodeId, channelId)
    );

    this.connection.on(
      "UpdateChannelLastValue",
      (projectId, channelId, value, timestamp, raw) =>
        implementation.update_last_value(
          projectId,
          channelId,
          value,
          timestamp,
          raw
        )
    );

    //this.connection.on('DeleteProject', (project) => implementation.deleteProject(project));

    this.connection.on("DeleteController", (projectId, controllerId) =>
      implementation.delete_cntrl(projectId, controllerId)
    );

    this.connection.on("DeleteZone", (projectId, zoneId) =>
      implementation.deleteZone(projectId, zoneId)
    );
    this.connection.on("DeleteNode", (projectId, zoneId, nodeId) =>
      implementation.deleteNode(projectId, zoneId, nodeId)
    );
    this.connection.on(
      "DeleteNodeChannel",
      (projectId, zoneId, nodeId, channelId) =>
        implementation.deleteNodeChannel(projectId, zoneId, nodeId, channelId)
    );

    this.connection.on(
      "NodeChannelPulse",
      (projectId, zoneId, nodeId, channelId, time, value) =>
        implementation.nodeChannelPulse(
          projectId,
          zoneId,
          nodeId,
          channelId,
          value,
          time
        )
    );

    this.connection.on("ReceiveMessage", (user, message) =>
      implementation.receiveMessage(user, message)
    );

    this.connection.on(
      "SampleValue",
      (projectId, controllerSerial, nodeSerial, channelNo, timestamp, value) =>
        implementation.add_sample_value(
          projectId,
          controllerSerial,
          nodeSerial,
          channelNo,
          timestamp,
          value
        )
    );

    this.connection.on(
      "FixVoltOutputIChanged",
      (projectId, controllerSerial, nodeSerial, timestamp, value) =>
        implementation.fv_out_current_changed(
          projectId,
          controllerSerial,
          nodeSerial,
          timestamp,
          value
        )
    );
    this.connection.on(
      "FixVoltOutputVChanged",
      (projectId, controllerSerial, nodeSerial, timestamp, value) =>
        implementation.fv_out_voltage_changed(
          projectId,
          controllerSerial,
          nodeSerial,
          timestamp,
          value
        )
    );
    this.connection.on(
      "FixVoltOutputRecoveryChanged",
      (projectId, controllerSerial, nodeSerial, timestamp, recovery) =>
        implementation.fv_out_recovery_changed(
          projectId,
          controllerSerial,
          nodeSerial,
          timestamp,
          recovery
        )
    );
    this.connection.on(
      "FixVoltOutputOnOffChanged",
      (projectId, controllerSerial, nodeSerial, timestamp, isOn) =>
        implementation.fv_out_ouput_changed(
          projectId,
          controllerSerial,
          nodeSerial,
          timestamp,
          isOn
        )
    );
  }
}

// For the following objects see Swagger for details
// - Project
// - Zone
// - Node
// - NodeChannel
