<template lang="html">
  <span class="filterbar_input" :title="hint">

    {{ name }}:

    <input
      class="wm_search_input "
      type="number"
      :placeholder="$txt.type_a_serial_to_filter"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :style="{ width: '140px' }"
    />
    <IconBtn
      :class="{ invisible: modelValue.length === 0 }"
      @clicked="$emit('update:modelValue', '')"
      :icon="$icon.clear_search"
    />
  </span>
</template>

<script>
export default {
  props: {
    modelValue: String,
    name: String,
    placeholder: String,
    disabled: Boolean,
    hint: String
  }
};
</script>

<style lang="css" scoped></style>
