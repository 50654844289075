<template>
  <div class="ProjectManagerView prj_manager_view">
    <FilterBar :title="$txt.all_projects" id="prjfilterbar">
      <FilterInput :name="$txt.name" v-model="name_filter" />
      <FilterInput :name="$txt.customer" v-model="customer_filter" />
      <FilterInput :name="$txt.client" v-model="client_filter" />
      <FilterInputNumeric :name="$txt.cntrl_serial_no" v-model="cntrl_serial_filter" style="width: 260px;"/>
    </FilterBar>
    <NotesAndErrorsPanel />

    <CreateProjectMenu
      v-if="add_project_mode"
      @close="add_project_mode = false"
    />

    <div
      :class="{
        gcont: this.$app.settings.show_map_prj_manager,
        disable_overflow: true
      }"
    >
      <div class="gleft">
        <TabMenu
          @select-tab="select_tab($event)"
          :tabs="[
            {
              name: $txt.active_projects + ` (${this.active_projects.length})`,
              key: 'active_projects'
            },
            {
              name: $txt.inactive_projects + ` (${this.inactive_projects.length})`,
              key: 'inactive_projects'
            }
          ]"
          :selected_tab="selected_tab"
          :tight="$ui.tight_mode"
        />
        <PaddedContent class="wm_scrollable" style="padding-bottom: 60px">
          <ResponsiveTable v-if="matching.length > 0">
            <!-- Adjust column headers to align with columns -->
            <template v-slot:head>
              <tr>
                <th @click="sort_projects_by('name')" class="clickable-header">{{ $txt.name }}</th>
                <th @click="sort_projects_by('lastSeen')" class="clickable-header">{{ $txt.last_seen }}</th>
                <th @click="sort_projects_by('customerName')" class="clickable-header">{{ $txt.customer }}</th>
                <th @click="sort_projects_by('client')" class="clickable-header">{{ $txt.client }}</th>
                <th @click="sort_projects_by('alarm')" class="clickable-header">{{ $txt.alarm }}</th>
                <th @click="sort_projects_by('alarmUnack')" class="clickable-header">{{ $txt.alarm_unack }}</th>
              </tr>
            </template>

            <tbody v-if="$app.projects != null && $app.customers != null">
              <tr
                class="wm_event_log_row"
                v-for="proj in matching"
                :key="proj.id"
                @click="
                  goto_route({
                      name: 'project',
                      params: { project_id: proj.id }
                    })
                "
              >
                <td>
                  <router-link
                    :to="{ name: 'project', params: { project_id: proj.id } }"
                    class="selectable-row"
                  >
                    <SmartProjectIcon :project="proj" />
                    {{ proj.name }}
                  </router-link>
                </td>
                <td><TimeLabel :time="proj.lastSeen" /></td>
                <td>
                  {{ customer_name(proj) }}
                </td>
                <td>
                  {{ proj.dto.info.client }}
                </td>
                <td class="icon-cell">
                  <StatusLamp :status="proj.alarm ? 'red' : 'green'" />
                </td>
                <td class="icon-cell">
                  <StatusLamp :status="proj.alarmUnack ? 'red' : 'green'" />
                </td>
              </tr>
            </tbody>
          </ResponsiveTable>
          <div v-else>
            <NoDataLabel
              v-if="this.$app.projects.length == 0"
              :text="$txt.access_to_no_project"
            />
            <NoDataLabel v-else :text="$txt.no_matching_projects_found" />
            <hr />
          </div>
        </PaddedContent>
      </div>
      <ProjectAtlas
        v-if="$app.settings.show_map_prj_manager"
        :projects="matching"
        :height="inside_height"
        class="gright"
      />
    </div>
    <div class="wm_title_bar">
      <Btn
        :title="$txt.create_new_project"
        :disabled=null
        id="add_project_btn"
        v-if="
          $appm.current_user_initialized() &&
            $app.session.current_user.can_create_projects
        "
        @clicked="
          add_project_mode = true;
          new_project_customer_id = $app.customers[0].id;
        "
      />
      <Btn
        :title="toggle_map_text"
        class="hol_btn"
        @clicked="
          $app.settings.show_map_prj_manager = !$app.settings
            .show_map_prj_manager;
          resize_map();
        "
      />
    </div>
  </div>
</template>

<script>
import TabHandler from "@/mixins/TabHandler";
import TableHelper from "@/mixins/TableHelper";
import ProjectAtlas from "./ProjectAtlas";
import CreateProjectMenu from "./CreateProjectMenu";
import router from '@/router';

export default {
  name: "ProjectManagerView",

  mixins: [TableHelper, TabHandler],
  components: { ProjectAtlas, CreateProjectMenu },

  data() {
    return {
      add_project_mode: false,
      name_filter: "",
      customer_filter: "",
      client_filter: "",
      cntrl_serial_filter: "",
      inside_height: 1000
    };
  },

  created: function() {
    this.select_tab("active_projects");
    this.$app.maintab = "projects";
    this.$appm.set_title(this.$txt.projects);

    if (this.$appm.current_user_token_is_valid()) {
      this.$appm.load_initial_data();
      let sortProp = localStorage.getItem("C3PrjManagerSortProp");
      if (sortProp != null) 
        this.sort_prop = sortProp;
      let sortOrder = localStorage.getItem("C3PrjManagerSortOrder");
      if (sortOrder != null) 
        this.reverse = (sortOrder  == "Reverse") ? true:false;
    }

    window.addEventListener("resize", this.resize_map);
  },

  mounted() {
    let savedSortProp = localStorage.getItem("C3PrjManagerSortProp");
      if (savedSortProp != null) {
        if (savedSortProp == this.sort_prop) {
          let savedSortOrder = localStorage.getItem("C3PrjManagerSortOrder");
          if (savedSortOrder != null) {
              // sort again with same prop to change sort order
            if (((savedSortOrder == "Reverse") && (!this.reverse)) 
                              ||
                ((savedSortOrder == "Ascending") && (this.reverse))) {
              this.sort_by(savedSortProp);
            }
          }
        }
        else
          this.sort_by(savedSortProp);     
      }
    this.resize_map();
  },

  unmounted() {
    window.removeEventListener("resize", this.resize_map);
  },

  methods: {
    resize_map() {
      let topmenu = document.getElementById("topmenu");
      let filterbar = document.getElementById("prjfilterbar");
      let footer = document.getElementById("footer");
      this.inside_height =
        window.innerHeight -
        filterbar.clientHeight -
        topmenu.clientHeight -
        footer.clientHeight;
    },
    goto_route(route) {
      router.push(route).catch(err => navigation_error(err));
      // Lock project from beginning
      this.$appm.saveProjectLock(route.params.project_id, true);
    },

    customer_name(proj) {
      if (this.$app.customers == null || this.$app.customers.length < 1)
        return "Loading...";
      let thisCustomer = this.$util.array_element_by_id(
        proj.dto.customerId,
        this.$app.customers
      );
      if (thisCustomer == null) {
        return "-";
      } else {
        return thisCustomer.name;
      }
    },

    navigation_error(err) {
      // A bug with vue router throws "Navigation Duplicated" error,
      // for now just ignore it
      //console.log(err.stack);
    },
    sort_projects_by(prop) {
      this.sort_by(prop);
      localStorage.setItem("C3PrjManagerSortProp", prop);
      localStorage.setItem("C3PrjManagerSortOrder",this.reverse ? "Reverse":"Ascending");
    },
    find_cntrl(project, serial) {
      for (let cntrl of project.cntrls) {
        if (cntrl.serial == serial) {
          return cntrl;
        }
      }
      // Cntrl device not assigned
      return null;
    },
  },

  computed: {
    active_projects() {
      // TODO: filter for real
      return this.$app.projects;
    },

    inactive_projects() {
      // TODO: filter for real
      return [];
    },

    projects_with_alarm() {
      // TODO: filter for real
      return [];
    },

    primary_filter() {
      switch (this.selected_tab) {
        case "active_projects":
          return this.active_projects;
        case "inactive_projects":
          return this.inactive_projects;
        default:
          return this.$app.projects;
      }
    },

    matching() {
      var filtered_by_name = this.$util.filter_table(
        this.primary_filter,
        this.name_filter,
        "name"
      );

      var filtered_customer = this.$util.filter_table(
        filtered_by_name,
        this.customer_filter,
        "customerName"
      );

      var filtered_client = this.$util.filter_table(
        filtered_customer,
        this.client_filter,
        "client"
      );

      let cntrl_serial = parseInt(this.cntrl_serial_filter);
      var filtered = [];
      if ((cntrl_serial > 0) && (cntrl_serial != NaN)) {
        for (let project of filtered_client) {
          if (this.find_cntrl(project,cntrl_serial) != null) {
            filtered.push(project);
          }
        }
      } else {
        filtered = filtered_client;
      }

      var sorted = this.$util.sort_table(filtered, this.sort_prop);
      if (this.reverse) sorted = sorted.reverse();
      return sorted;
    },

    toggle_map_text() {
      if (this.$app.settings.show_map_prj_manager) {
        return this.$txt.hide_atlas;
      }
      return this.$txt.show_atlas;
    }
  }
};
</script>

<style>
.gcont {
  display: flex;
  overflow: hidden;
}

.disable_overflow {
  overflow: hidden;
}

.gleft {
  height: 100%;
  flex: 65%;
}

.gright {
  flex: 35%;
}
</style>
