<template lang="html">
  <div class="queueviewer">
    <div class="a row">
      <h4>Send Queue</h4>
      <Btn class="hol_btn" title="List projects" @click="list_projects()" />
      <Btn class="hol_btn" title="List nodes" @click="list_nodes()" />
      <Btn class="hol_btn" title="List channels" @click="list_channels()" />
      <Btn class="hol_btn" title="Clear" @click="clear()" />

      <table>
        <thead>
          <tr>
            <th>Method</th>
            <th>Caller name</th>
            <th>Identification</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in Q.send_queue" :key="item">
            <td>{{ item.data.method }}</td>
            <td>{{ item.data.command }}</td>
            <td>{{ item.data.identification }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="b row">
      <h4>Newly sent</h4>
      <table>
        <thead>
          <tr>
            <th>Method</th>
            <th>Caller name</th>
            <th>Identification</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="item in Q.recently_sent" :key="item">
            <td>{{ item.data.method }}</td>
            <td>{{ item.data.command }}</td>
            <td>{{ item.data.identification }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="c row">
      <h4>Arrive queue</h4>
      <table>
        <thead>
          <tr>
            <th>Method</th>
            <th>Caller name</th>
            <th>Identification</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="item in Q.arrive_queue" :key="item">
            <td>{{ item }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Network from "@/networking";
export default {
  computed: {
    Q() {
      return Network.queue;
    }
  },

  methods: {
    list_channels() {
      Network.channel.list(1, 1, 49);
    },
    list_nodes() {
      Network.node.list(1, 1);
    },
    list_projects() {
      Network.project.list();
    },

    clear() {
      Network.queue.clear();
    }
  }
};
</script>

<style lang="less" scoped>
.queueviewer {
  width: 100%;
  min-height: 500px;
  display: grid;
  grid-auto-columns: 1fr;
}

.row {
  padding: 10px;
  grid-row: 1;
}

.a {
}
th {
  padding-left: 4px;
}

.b {
  border-left: 1px solid gray;
}

.c {
  border-left: 1px solid gray;
}
</style>
