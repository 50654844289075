<template>
  <div
    class="wm_controller_hardware"
    :class="{
      wm_clickable: mode == 'click',
      wm_grabbable: mode == 'grab',
      wm_ungrabbable: mode == 'ungrab'
    }"
    :draggable="mode == 'grab'"
    v-if="!(mode == 'click' && !available_for_assignment)"
    @dragstart="drag_device($event, device)"
    :title="hint"
  >
    <SmallIcon
      icon="drag_indicator"
      color="gray"
      size="13"
      :class="{
        invisible: mode != 'drag'
      }"
    />
    <StatusLamp v-if="available_for_assignment" :status="computed_color" />
    <StatusLamp v-else :status="computed_not_available_color" />
    {{ $txt.type }}: <b>{{ type_name }}</b> {{ $txt.serial_no }}:
    <b>{{ device.serial }}</b>
    <span> → {{ device_status }} </span>
  </div>
</template>

<script>
export default {
  name: "DeviceRow",

  data() {
    return {
      assigned_to: ""
    };
  },
  props: {
    device: Object,
    clickmode: {
      default: false
    }
  },

  methods: {
    drag_device(ev, device) {
      if (ev) {
        ev.dataTransfer.setData("text", "drag_type_node");
        this.$app.dragged_item = device;
      }
    }
  },

  computed: {
    mode() {
      if (
        this.available_for_assignment &&
        this.is_online &&
        !this.$app.sltd.project.edit_locked &&
        (this.$app.sltd.project.dto.canSetup
      )) {
        if (this.clickmode) return "click";
        return "grab";
      }
      return "ungrab";
    },

    available_for_assignment() {
      for (let node of this.$app.sltd.project.all_nodes) {
        // Check only nodes belonging to this controller
        if (node.dto.controllerId == this.$app.sltd.cntrl.id) {
          if (node.locals.serialNumber == this.device.locals.serialNumber) {
          // eslint-disable-next-line
          this.assigned_to = node.locals.name;
          return false;
          }
        }
      }
      // eslint-disable-next-line
      this.assigned_to = "";
      return true;
    },

    device_status() {
      let deviceString = "";
      if (this.is_online) {
        if (this.available_for_assignment) {
          deviceString = this.$txt.available;  
        }
        else
        {
          deviceString = this.assigned_to;
        }
      }
      else {
        if (this.available_for_assignment) {
          deviceString = "(" + this.$txt.offline + ")";
        }
        else {
          deviceString = this.assigned_to + " (" + this.$txt.offline + ")";
        }  
      }
      return deviceString;
    },

    type_name() {
      return this.$appm.get_node_type_name(this.device.dto.capability);
    },

    hint() {
      if (this.$app.sltd.project.edit_locked) {
        return this.$txt.project_is_locked;
      }
      if (!this.available_for_assignment) {
        return this.$txt.device_already_assigned;
      }

      if (!this.is_online) {
        return this.$txt.device_is_offline;
      }
      if(!this.$app.sltd.project.dto.canSetup){
        return this.$txt.dont_have_rights_to_assign_device
      }
      switch (this.mode) {
        case "grab":
          return this.$txt.drag_to_assign_device_hint;

        case "click":
          return "";

        default:
          return this.$txt.device_already_assigned_hint;
      }
    },
    is_online() {
      //Check if the node is connected/online
      return this.device.dto.connected;
    },
    computed_color() {
      //If device is connected show green colour, if not white.
      if (this.is_online) {
        return "green";
      } else return "white";
    },
    computed_not_available_color() {
      if (this.is_online) {
        return "blue";
      } else return "white";
    }
  }
};
</script>
